<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Resultados Loterias</h1>

      <v-form>
        <v-row>
          <v-col md="2">
            <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                  <!-- <v-date-picker v-model="date" @change="getLotteriesGroups(date)"/> -->
                <v-text-field
                  v-model="dtInicialString"
                  v-mask="['##/##/####']"
                  :label="$gettext('Data Inicial')"
                  @input="testeDate('inicial', dtInicialString)"
                  class="mt-2"
                  dense
                  v-on="on">
                  <template v-slot:append>
                    <v-icon v-on="on">
                      event
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                no-title
                scrollable
                locale="pt-br"
                :type="'date'"
                @input="closeModalDatePicker('inicial', date)"
                :color="variables.colorPrimary">
                  <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="2" class="pt-0">
            <v-select
              :items="lotteries"
              label="Loteria"
              v-model="selectedLottery"
            />
          </v-col>

          <!-- <v-col md="1">
            <v-text-field v-model="region" label="Região" filled :error-messages="regionErrors" @blur="$v.region.$touch()" readonly></v-text-field>
          </v-col> -->
        <v-col class="d-flex">
          <v-btn class="mt-1" @click.prevent="submit(selectedLottery, date)" color="info" :loading="isLoading" @mouseover="visible = true">Buscar</v-btn>
        </v-col>
        </v-row>
      </v-form>
      <v-row>
      </v-row>

      <v-row v-if="!cards.length">
        <h1>Nenhum resultado para mostrar.</h1>
      </v-row>

      <v-row v-else>
        <v-col md="3" v-for="card in cards" :key="card.title">
          <v-card elevation="2" outlined shaped>
            <v-card-title>{{card.cod}}</v-card-title>
            <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">Prêmio</th>
                    <th class="text-left">Resultado</th>
                    <th class="text-left">Grupo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="prize in card.prizes" :key="prize.cod">
                    <td>{{prize.cod}}</td>
                    <td>{{prize.number}}</td>
                    <td>{{prize.group}}</td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import mixinRelatorio from '@/views/relatorios/mixins/relatorio'
import moment from 'moment'
import variables from '@/assets/styles/helpers/_variables.scss'
const namespaceStore = 'relatorioResultados'

export default {
  name: 'ResultadoLoterias',
  mixins: [mixinRelatorio],
  data: () => ({
    selectedLottery: '',
    dtInicialString: moment().format('DD/MM/YYYY'),
    date: new Date().toISOString().substr(0, 10),
    region: 'GYN',
    isLoading: false
  }),

  watch: {
    date(data) {
      this.getLotteriesGroups(data);
      // this.clearData()
      // this.limparLoterias()
    },
  },
  mounted() {
    let self = this;
    self.$v.region.$touch();
    self.getLotteriesGroups(self.date);
    // self.selectedLottery = self.lotteries[0] || ''
    // self.submit(self.selectedLottery, self.date)
  },
  computed: {
    ...mapGetters(namespaceStore, ['cards', 'lotteries']),
    variables: () => variables,
    dateErrors () {
      const errors = []
      if (!this.$v.date.$dirty) return errors
      !this.$v.date.required && errors.push('Data é obrigatório.')
      return errors
    },
    regionErrors () {
      const errors = []
      if (!this.$v.region.$dirty) return errors
      !this.$v.region.required && errors.push('Região é obrigatório.')
      return errors
    }
  },
  methods: {
    closeModalDatePicker(dt, date) {
      this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    ...mapActions(namespaceStore, ['getSweepstakesResults', 'getLotteries']),
    submit (cod, date) {
      let self = this
      if (!self.$v.$invalid) {
        self.isLoading = true
        self.getSweepstakesResults({cod: cod.toUpperCase(), date: date}).then( async () => {
          self.isLoading = false
        }).catch(() => {
          self.isLoading = false
        })
      }
    },
    getLotteriesGroups (date) {
      let self = this
      self.getLotteries({date}).then(() => {
        self.selectedLottery = self.lotteries[0]
      })
    },
  },
  validations () {
    return {
      date: { required },
      region: { required }
    }
  }
}
</script>