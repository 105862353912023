import moment from 'moment'
import { size, each } from 'lodash'
import { errorSnackbar } from '@/core/service/utils'

export default {
  components: {
    dateTimerPicker: () => import('@/views/components/dateTimePicker')
  },
  data: () => ({
    timerRequest: '',
    visible: false,
    form: {
      dtInicial: moment({ hour: 0 }).format('DD/MM/YYYY HH:mm'),
      dtFinal: moment({ hour: 23, minute: 59, seconds: 59 }).format('DD/MM/YYYY HH:mm')
    },
    datetime: new Date(),
    loader: true,
    loaderPaginacao: false,
    page: 1,
    pageMax: 1,
    minHora: '00:00',
    items: [
      { title: 'PDF', value: 'pdf' },
      { title: 'XLS', value: 'xls' }
    ],
    selectConcurso: null
  }),
  mounted () {
    moment().locale(this.$store.getters.Locale)
    const vm = this
    // setTimeout(() => {
    vm.buscarRelatorio()
    // }, 1000)
    this.page = 1
    // this.timerRequest = window.setInterval(this.buscarRelatorio(), 2 * 60 * 1000)
    // this.timerRequest = window.setInterval(() => {
    //   vm.buscarRelatorio()
    // }, 300000)
    console.log('mixins')
  },
  beforeDestroy () {
    clearInterval(this.timerRequest)
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    sendDates () {
      moment().locale(this.$store.getters.Locale)
      if (size(this.form) !== 2){
        return {
          dtInicial: moment(this.form.dtInicial, 'DD/MM/YYYY HH:mm').add(-1, 'days').utcOffset(-3, false).format('YYYY-MM-DDTH:mm:ss.000z'),
          dtFinal: moment(this.form.dtFinal, 'DD/MM/YYYY HH:mm').utcOffset(-3, false).format('YYYY-MM-DDTH:mm:ss.000z'),
          userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1
        }
      }
      return {
        dtInicial: moment(this.form.dtInicial, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTH:mm:ss.000z'),
        dtFinal: moment(this.form.dtFinal, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTH:mm:ss.000z'),
        userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1,
        concurso: this.selectConcurso ? `where s.cod = '${this.selectConcurso}'` : null,
        entidade: this.selectTypeEntity ? `and et.id in (${this.selectTypeEntity})` : null
      }
    }
  },
  watch: {
    // eslint-disable-next-line
    dados (val) { 
      this.setUrlImages()
    },
    'form.dtInicial' (val) {
      let hora = moment(val).format('H:mm')
      this.minHora = hora
    }
  },
  methods: {
    InitGetRelatorio () {
      const vm = this
      let datas = {
        dtInicial: moment(this.form.dtInicial, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTH:mm:ss.000z'),
        dtFinal: moment(this.form.dtFinal, 'DD/MM/YYYY HH:mm').utcOffset(-3, false).format('YYYY-MM-DDTH:mm:ss.000z'),
        userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1
      }
      vm.getHtml({tipo: 'html', page: vm.page, ...datas})
        .then(() => {
          setTimeout(() => {
            vm.setUrlImages()
            vm.formatStyleRelatorio()
          }, 500)
        })
        .catch(() => {
          errorSnackbar('Não possui relatório')
        })
        .finally(() => {
          this.loader = false
        })
    },
    buscarRelatorio (clear = false) {
      const vm = this
      if (clear) {
        vm.page = 1
      }
      vm.loaderPaginacao = true
      this.loader = true
      vm.getHtml({tipo: 'html', ...vm.form, page: vm.page, ...vm.sendDates}).then(() => { vm.loader = false })
        .then(() => {
          setTimeout(() => {
            vm.formatStyleRelatorio()
            vm.setUrlImages()
          }, 500)
        })
        .catch(() => {
          errorSnackbar('Não possui relatório')
        })
        .finally(() => this.loader = false)

    },
    downalod (tipo) {
      // this.requestRelatorio({tipo: tipo, ...this.form, page: this.page, ...this.sendDates }).then(async (res) => {
      this.requestRelatorio({tipo: tipo, ...this.form, ...this.sendDates }).then(async (res) => {
        window.location = res.data.url
      })
    },
    incrementPage(operation) {
      if (operation === '+' && this.pageMax !== this.page) {
        this.page = this.page + 1
        this.buscarRelatorio()
      } 
      if (operation === '-' && this.page !== 1) {
        this.page = this.page - 1
        this.buscarRelatorio()
      }
    },
    setUrlImages () {
      let images = document.querySelectorAll('table tbody img')
      if (size(images) === 0) return false
      each(images, (val, key) => {
        val.src= this.getImgUrl(key)
      })
    },
    getImgUrl(pic) {
      return require('@/assets/img-jasper/'+pic +'.png')
    },
    formatStyleRelatorio () {
      let table = document.querySelectorAll('table tbody tr td')
      let remove = table[0]
      remove.parentNode.removeChild(remove)
    // eslint-disable-next-line
      let element = table[1].style.width = '100%'
      let tabelaView = table[1].querySelector('.jrPage')
      if (!tabelaView) return ''
      tabelaView.style.width = '100%'
      this.totalPages()
    },
    totalPages () {
      // eslint-disable-next-line  no-useless-escape
      const regex = /\>([^\\].*)\</gm
      let table = document.querySelectorAll('table tbody tr td')
      // let tamanho = size(table)
      let texto = ''
      table.forEach(item => {
        texto += item.innerText
      })
      const resultRegex = regex.exec(texto)
      if (resultRegex !== null)
        this.pageMax = parseInt(resultRegex[1])
      // let html = table[87]
      // let span = html.querySelector('span')
      // if(span) {
      //   this.pageMax = parseInt(span.innerText) || 1
      // }
    },
    allowedDates (val) {
      let atual = moment(this.form.dtInicial).add(-1, 'days')
      return atual.isBefore(val)
    },
    disabledHoras (val) {
      if(!val) return ''
      let atual = moment(this.form.dtInicial).format('H')
      return val > atual
    },
    compararDataEnd (val) {
      let inicial = moment(this.form.dtInicial).format('YYYYY-MM-DD')
      let dtIni = moment(inicial, 'YYYYY-MM-DD')
      this.form.dtFinal = moment(`${val} 23:59:59`).toDate()
      if (dtIni.isSame(moment(val, 'YYYYY-MM-DD')) ) {
        this.minHora = moment(this.form.dtInicial).add(5, 'minutes').format('H:mm')
        return ''
      }
      this.minHora = '00:00'
    }
  }
}